// import React, { useRef, useEffect } from "react";
import React from "react";
import CleanLayout from "layouts/Clean";
import { SEO, Title, Text, FoldingTeam, Intro } from "components";
import Heart from "images/svg/heart.svg";
import World from "images/svg/world.svg";
import Bulb from "images/svg/bulb.svg";
import { graphql, useStaticQuery } from "gatsby";

const pageProperties = {
  title: "Ti presento il Team.",
  description:
    "Perchè a fare la differenza sul tuo progetto non sarà la tecnologia, ma le persone che ci lavorano sopra.",
};

function ChiSiamoPage() {
  const {
    allContentfulTeam: { group },
  } = useStaticQuery(GET_REALMS);

  return (
    <CleanLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Trame People: il team di Trame Digitali."
        description = "Perchè a fare la differenza sul tuo progetto non sarà la tecnologia, ma le persone che ci lavorano sopra."
      />

      {/* <div className="w-full md:mt-32" ref={ref}> */}
      <div className="w-screen px-8 md:px-28">
        <Intro {...pageProperties} />
        
        <section className="w-full mb-16">
                <Text>
                <i>"La nostra storia è proprio quella che ti aspetti in un settore come il nostro: inizia per <strong>sfida</strong> e <strong>curiosità</strong>, agli inizi del 2000, in una soffitta. <br/>
                Lavoravo su pionieristici siti web, e-commerce, server e sperimentavo costantemente le nuove tecnologie. Nel corso del tempo ho consciuto altri ragazzi mossi dallo stesso spirito e abbiamo deciso di fare un po' di strada insieme.
                <br/>In questi anni abbiamo lavorato con realtà come Il Grande museo del Duomo di Firenze, BTO, Palmucci, Il Sito d’Italia, per citarne alcune.
                <br/>La soffitta è diventata un soppalco, infine un’azienda e un ufficio tutto nostro: siamo cresciuti di numero e qualità continuando in questi anni a servire decine di clienti e <strong>instaurando collaborazioni durature</strong>"
                </i>
                <br/>
                Cosimo
                </Text>
        </section>

        <section className="w-full mb-16">
          <div className="flex flex-col">
            <div className="relative w-min">
              <Bulb className="fill-current text-yellow-300 w-20 h-20 transform rotate-12 absolute -top-8 -right-12 z-0 hover:rotate-6 transition duration-300 ease-in-out"></Bulb>
              <div className="relative z-10">
                <Title>Founders</Title>
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between">
              <FoldingTeam people={group[0].nodes} />
            </div>
          </div>
        </section>
        <section className="w-full  mb-16">
          <div className="flex flex-col">
            <div className="relative w-min">
              <Heart className="fill-current text-primary w-20 h-20 transform rotate-12 absolute -top-8 -right-12 z-0 hover:rotate-6 transition duration-300 ease-in-out"></Heart>
              <div className="relative z-10 flex flex-col">
                <Title>
                  Trame<i>People</i>
                </Title>
              </div>
            </div>
            <Text>
              "In questo nostro percorso le necessità dei clienti sono cambiate e ci hanno portato ad avvicinare nuove figure professionali per offrire un servizio sempre più completo.
              Ma soprattutto con l'idea comune di costruire qualcosa di condiviso dove tutti abbiano la possibilità di essere determinanti e far crescere il gruppo e il cliente."
              Loro li puoi trovare tutti i giorni
            </Text>
            <div className="flex flex-col md:flex-row justify-between">
              <FoldingTeam people={group[1].nodes} />
            </div>
          </div>
        </section>
        <section className="w-full  mb-32">
          <div className="flex flex-col">
            <div className="relative w-min">
              <World className="fill-current text-blue-400 w-20 h-20 transform rotate-12 absolute -top-8 -right-12 z-0 hover:rotate-6 transition duration-300 ease-in-out"></World>
              <div className="relative z-10">
                <Title>
                  Trame<i>World</i>
                </Title>
              </div>
            </div>
            <Text>
              Loro invece li troverai un po' meno spesso, ma sono comunque professionalità che potrebbero essere necessarie per il tuo progetto e che collaborano con noi.
            </Text>

            {[...Array(Math.ceil(group[2]?.nodes?.length / 4))].map((v, i) => {
              // console.log(group[2].nodes.slice(i * 4, 3 + 4 * i));
              return (
                <div
                  key={i}
                  className="flex flex-col md:flex-row justify-between"
                >
                  <FoldingTeam
                    people={group[2].nodes.slice(i * 4, 4 + 4 * i)}
                  />
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </CleanLayout>
  );
}

const GET_REALMS = graphql`
  query GetRealms {
    allContentfulTeam(filter: { node_locale: { eq: "it" }, realm: {} }) {
      group(field: realm) {
        nodes {
          name
          position
          description {
            description
          }
          avatar {
            gatsbyImageData(placeholder: TRACED_SVG, formats: [WEBP, PNG])
          }
        }
      }
    }
  }
`;

export default ChiSiamoPage;
